<template>
	<div class="card">
		<div class="card-body">
			<div class="row">
				<div class="col-md-3">
					<h5>
						<strong>Registro de cotización</strong>
					</h5>
				</div>
				<div class="col-md-12">
					<hr class="hrText" data-content="♦" />
				</div>
			</div>
			<InsertOrUpdateQuotation />
		</div>
	</div>
</template>

<script>
//
import InsertOrUpdateQuotation from '@/components/quotations/insertOrUpdate'

export default {
	name: 'QuotationDetailView',
	components: {
		InsertOrUpdateQuotation,
	},
}
</script>
