<template>
	<div>
		<a-spin :tip="spinnerLoaderLabel || priceTabSpinnerLoaderLabel" size="large" :spinning="spinnerLoader || priceTabSpinnerLoader">
			<div class="row">
				<div class="col md-6">
					<div class="row">
						<div class="col-md-12">
							<b>Cliente </b>
							<a-icon type="plus" :style="{ fontSize: '14px', color: 'green' }" @click="onOpenModal('searchClient')" />
							<div>{{ quotation.client_name | replaceIfLogicalFalse('A quién corresponda') }}</div>
						</div>
						<div class="col-md-12 pt10">
							<b>Texto superior </b>
							<a-textarea v-model="quotation.introduction" rows="7" />
						</div>
					</div>
				</div>
				<div class="col md-6">
					<div class="row">
						<div class="col-md-6">
							<b>Móvil </b>
							<a-input v-model="quotation.phone" autocomplete="off" />
						</div>
						<div class="col-md-6">
							<b>Email </b>
							<a-input v-model="quotation.email" autocomplete="off" />
						</div>
						<div class="col-md-12 pt10">
							<b>Link de Trengo</b>
							<a-input v-model="quotation.trengo" autocomplete="off" />
						</div>
						<div class="col-md-12 pt10">
							<b>Texto inferior </b>
							<a-textarea v-model="quotation.aditional_notes" rows="7" />
						</div>
					</div>
				</div>
			</div>
			<div class="row pt20">
				<!-- <div class="col-md-6">
					<div v-if="!isClosed">
						<b>Buscador de servicios </b>
						<a-input-search v-model="serviceText" placeholder="Ingresa palabra clave del servicio" enter-button @search="onSearchService" />
					</div>
				</div> -->
				<div class="col-md-6">
					<div v-if="!isClosed">
						<b>Buscador de productos por SKU / código de barras </b>
						<a-input-search v-model="productSKU" placeholder="Ingresa texto o SKU para buscar" enter-button @search="onSearch" />
					</div>
				</div>
				<div class="col-md-12">
					<hr class="hrDotted" />
				</div>
			</div>
			<div class="row">
				<div class="col-md-12">
					<div v-for="(element, index) in cart" :key="index">
						<div class="row" v-if="index > 0">
							<div class="col-12">
								<hr class="hrDotted" />
							</div>
						</div>
						<div class="row">
							<div class="col-md-1 text-center">
								<div style="font-size: 50px; text-align: center; height: 100px; vertical-align: middle; line-height: 130px">
									{{ index + 1 }}
								</div>
							</div>
							<div class="col-md-11">
								<div class="row">
									<div class="col-md-3">
										<b>Cant.</b>
										<a-input v-if="element.measurement_unit == 'liter'" v-money="money" v-model="element.quantity" />
										<a-input v-else v-mask="'####'" v-model="element.quantity" />
									</div>
									<div class="col-md-3">
										<b>Unidad de medida</b>
										<a-select v-model="element.measurement_unit" style="width: 100%">
											<a-select-option value="liter">Litro</a-select-option>
											<a-select-option value="piece">Pieza</a-select-option>
											<a-select-option value="service">Servicio</a-select-option>
										</a-select>
									</div>
									<div class="col-md-6">
										<b>Descripción</b>
										<a-input v-model="element.description" />
									</div>
									<div class="col-md-3 pt10">
										<b>Precio unitario</b>
										<a-input prefix="$" v-money="money" v-model="element.unit_price" />
									</div>
									<div class="col-md-3 pt10">
										<b>Subtotal</b>
										<a-input prefix="$" v-money="money" :value="getSubtotal(index)" disabled />
									</div>
									<div class="col-md-3 pt10">
										<b>Tiempo de entrega</b>
										<a-input v-model="element.delivery_time" />
									</div>
									<div class="col-md-12 text-right" v-if="cart.length > 1">
										<span class="pointer" @click="onDeleteElement(index)">Eliminar partida</span>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="row pt20">
				<div class="col-md-4 text-left">
					<a-button class="btn btn-warning" icon="arrow-left" @click="onCancel">Regresar</a-button>
				</div>
				<div class="col-md-8 text-right">
					<!-- <a-button class="btn btn-info" icon="double-right" @click="onCreateQuote" v-if="!isNewQuotation">Promover a Cita</a-button> -->
					<a-button class="btn btn-success ml7" icon="save" @click="onSave">Guardar</a-button>
				</div>
			</div>
		</a-spin>
		<!-- Búsqueda de cliente -->
		<a-modal :visible="modal.searchClient.visible" v-if="modal.searchClient.visible" title="Añadir cliente" :closable="true" width="75%">
			<template slot="footer">
				<a-button key="back" @click="onCloseModal('searchClient')"> Cancelar </a-button>
				<a-button key="submit" class="btn btn-success" @click="selectClient"> Seleccionar </a-button>
			</template>
			<clientsQuickSearchComponent ref="clientsQuickSearchComponent" :visible="modal.searchClient.visible" :showAddButton="false" :showAddButtonAfterNullSearch="true"
										 @selectedRecord="onSelectedClient" @addClient="onAddClient" />
		</a-modal>
		<!-- Registro de cliente -->
		<a-modal :visible="modal.addClient.visible" v-if="modal.addClient.visible" title="Añadir cliente" :closable="true" width="75%">
			<template slot="footer">
				<a-button key="back" @click="onCloseModal('addClient')"> Cancelar </a-button>
				<a-button key="submit" class="btn btn-success" @click="onCreateClient"> Añadir </a-button>
			</template>
			<clientsDetailGeneralComponent :showControls="false" :controlTaller="true" ref="clientsDetailGeneral" @selectedRecord="onSelectedClient" />
		</a-modal>
		<!-- Listado de Productos -->
		<a-modal :visible="modal.products.visible" title="Listado de Productos" :closable="true" @cancel="onCloseModal('products')" width="80%">
			<template slot="footer">
				<a-button key="back" @click="onCloseModal('products')"> Cerrar </a-button>
			</template>
			<productsListComponent @selectedProduct="onSelectProduct" />
		</a-modal>
		<!-- Listado de Servicios -->
		<a-modal :visible="modal.services.visible" title="Listado de Servicios" :closable="true" @cancel="onCloseModal('services')" width="80%">
			<template slot="footer">
				<a-button key="back" @click="onCloseModal('services')"> Cerrar </a-button>
			</template>
			<servicesListComponent :enableSearch="false" :enableEdit="false" :enableSelect="true" ref="servicesListComponent" />
		</a-modal>
	</div>
</template>

<script>
//
import { mapGetters } from 'vuex'
import Swal from 'sweetalert2'
import _ from 'lodash'
import numeral from 'numeral'
import moment from 'moment'
import { mask } from 'vue-the-mask'
import { VMoney } from 'v-money'
import utilities from '@/services/utilities'
//
import productsListComponent from '@/components/productsList'
import servicesListComponent from '@/components/servicesList'
import clientsQuickSearchComponent from '@/components/clients/quickSearch'
import clientsDetailGeneralComponent from '@/components/clients/detail/partials/general'

export default {
	name: 'QuotationsDetailComponent',
	directives: {
		mask,
		money: VMoney,
	},
	components: {
		productsListComponent,
		servicesListComponent,
		clientsQuickSearchComponent,
		clientsDetailGeneralComponent,
	},
	data() {
		return {
			money: {
				decimal: '.',
				thousands: ',',
				precision: 2,
			},
			modal: {
				products: {
					visible: false,
				},
				services: {
					visible: false,
				},
				searchClient: {
					visible: false,
				},
				addClient: {
					visible: false,
				},
			},
			productSKU: '',
			cart: [],
			quotation: {
				client_id: '',
				client_name: '',
				phone: '',
				email: '',
				trengo: '',
				introduction: 'Adjuntamos la cotización solicitada:',
				aditional_notes: `La cotización no incluye servicios o materiales adicionales a los listados en la descripción.\nEn el caso de refacciones y/o accesorios se requiere un pago anticipado del 100%.`,
			},
			label: 'Confirmar',
			addedProducts: false,
			serviceText: '',
			precioFactor: process.env.VUE_APP_FACTOR,
		}
	},
	computed: {
		...mapGetters('products', ['productsList']),
		...mapGetters('clients', ['clientsList']),
		...mapGetters('quotations', ['spinnerLoaderLabel', 'spinnerLoader', 'actualQuotation']),
		...mapGetters('priceTab', ['priceTabList']),
		hasCart() {
			return this.cart.length == 0 ? false : true
		},
		total() {
			let summ = 0
			this.cart.forEach((e) => {
				summ += numeral(e.unit_price).value() * numeral(e.quantity).value()
			})
			return summ
		},
		isClosed() {
			return false
		},
		priceTabSpinnerLoader() {
			return this.$store.state.priceTab.spinnerLoader
		},
		priceTabSpinnerLoaderLabel() {
			return this.$store.state.priceTab.spinnerLoaderLabel
		},
		isNewQuotation() {
			return isNaN(this.$route.params.id)
		},
	},
	mounted() {
		if (!this.isNewQuotation) {
			this.quotation = {
				client_id: utilities.objectValidate(this.actualQuotation, 'quotationData.client_id', ''),
				phone: utilities.objectValidate(this.actualQuotation, 'quotationData.phone', ''),
				email: utilities.objectValidate(this.actualQuotation, 'quotationData.email', ''),
				trengo: utilities.objectValidate(this.actualQuotation, 'quotationData.email', ''),
				introduction: utilities.objectValidate(this.actualQuotation, 'quotationData.introduction', ''),
				aditional_notes: utilities.objectValidate(this.actualQuotation, 'quotationData.aditional_notes', ''),
			}
			this.actualQuotation.quotationElements.forEach((e) => {
				this.onSelectProduct({
					quantity: e.quantity,
					prices: JSON.stringify({ percent: '', fixed_price: '', units: '', sale_price: e.unit_price, vat: '' }),
					id: e.id,
					label: e.description,
					measurement_unit: e.measurement_unit,
					delivery_time: e.delivery_time,
				})
			})
		}
	},
	destroyed() {
		this.$store.commit('quotations/SET_STATE', {
			actualQuotation: {
				quotationData: {},
				quotationElements: [],
			},
		})
	},
	methods: {
		moment,
		numeral,
		filterOption(input, option) {
			return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
		},
		onReloadClients() {
			this.$store.dispatch('clients/GET', { controlTaller: true })
		},
		onSearch() {
			if (this.productSKU == '' || this.productSKU.length < 3) {
				Swal.fire({
					title: 'Caja',
					text: 'Debes ingresar texto para la búsqueda',
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
			} else {
				this.$store.dispatch('products/SEARCH', { searchText: this.productSKU })
			}
		},
		onSearchService() {
			if (this.serviceText == '') {
				Swal.fire({
					title: 'Caja',
					text: 'Debes ingresar texto para la búsqueda',
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
			} else {
				//
				let payload = {
					searchText: this.serviceText,
				}
				this.$store.dispatch('priceTab/SEARCH', { payload })
			}
		},
		onDeleteElement(index) {
			Swal.fire({
				title: 'Atención',
				html: `¿Deseas eliminar la partida con <b>"${this.cart[index].description}"</b> de la cotización?`,
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					this.cart.splice(index, 1)
				}
			})
		},
		onOpenModal(modalType) {
			this.modal[modalType].visible = true
		},
		onCloseModal(modalType) {
			this.modal[modalType].visible = false
			if (modalType == 'services') {
				this.serviceText = ''
				let selectedRowKeys = _.cloneDeep(this.$refs.servicesListComponent.selectedRowKeys)

				selectedRowKeys.forEach((id) => {
					let record = this.priceTabList.find((e) => e.price_tab_id == id)
					// console.log('record-->',record);
					// Se añaden productos
					record.products.forEach((product) => {
						this.onSelectProduct(product)
					})
					// Se añaden mano de obra
					let prices = JSON.stringify({ percent: 0, fixed_price: 0, units: '', sale_price: (record.required_time / 60) * this.precioFactor, vat: 0 })
					this.onSelectProduct({
						id: 89,
						sku: 'SER-MEC',
						label: 'Servicio por mano de obra',
						prices,
						measurement_unit: 'E48',
						quantity: 1,
						sat_key: '78181500',
					})
				})

				this.$refs.servicesListComponent.cleanData()
				this.$store.state.priceTab.priceTabList = []
			}
		},
		getPrice(string) {
			if (!string) {
				return 0
			}
			return numeral(JSON.parse(string).sale_price).value()
		},
		onSelectProduct(product) {
			let quantity = utilities.objectValidate(product, 'quantity', 1)
			let sale_price = this.getPrice(product.prices)
			let index = this.cart.findIndex((e) => e.id == product.id)
			let delivery_time = product.delivery_time

			if (index == -1) {
				this.cart.push({
					id: product.id,
					quantity,
					description: product.label,
					measurement_unit: product.measurement_unit == 'H87' ? 'piece' : 'service',
					unit_price: numeral(sale_price).format('$0,0.00'),
					delivery_time,
				})
			} else {
				let allCart = _.cloneDeep(this.cart)
				this.cart = []
				let cart = _.cloneDeep(allCart[index])
				quantity = cart.quantity + 1

				cart = {
					...cart,
					quantity,
				}

				allCart[index] = _.cloneDeep(cart)
				this.cart = allCart
			}
			this.productSKU = ''
			this.onCloseModal('products')
		},
		onCancel() {
			this.$router.replace('/taller')
		},
		getSubtotal(index) {
			return numeral(numeral(this.cart[index].unit_price).value() * numeral(this.cart[index].quantity).value()).format('0,0.00')
		},
		onSave() {
			Swal.fire({
				title: 'Atención',
				html: `¿Deseas confirmar la cotización?`,
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					let payload = {
						...this.quotation,
						elements: _.cloneDeep(this.cart),
					}
					this.$store.dispatch('quotations/CREATE', { payload, redirect: true })
				}
			})
		},
		onCreateQuote() {
			let errors = []

			if (!!!utilities.objectValidate(this.quotation, 'phone', false)) {
				errors.push(`- Es necesario ingresar al menos el número telefónico del cliente.`)
			}

			if (errors.length) {
				Swal.fire({
					title: 'Atención',
					html: errors.join('<br>'),
					icon: 'warning',
					confirmButtonText: 'Ok',
				})
				return false
			}
			Swal.fire({
				title: 'Atención',
				html: `¿Deseas que la cotización sea convertida en una cita?`,
				icon: 'warning',
				showCancelButton: true,
				reverseButtons: true,
				confirmButtonColor: '#41b883',
				confirmButtonText: 'Sí, continuar',
				cancelButtonColor: '#f5222e',
				cancelButtonText: 'No, cancelar',
			}).then((result) => {
				if (result.isConfirmed) {
					console.log('onCreateQuote')
				}
			})
		},
		selectClient() {
			this.$refs.clientsQuickSearchComponent.onSelectedRecord()
		},
		onSelectedClient(payload) {
			// console.log('onSelectedClient-->', payload)
			this.quotation.client_id = payload.id
			this.quotation.client_name = `${payload.client_name} ${payload.client_surname}`
			this.quotation.phone = utilities.objectValidate(payload, 'primary_phone', '')
			this.quotation.email = utilities.objectValidate(payload, 'email', '')

			this.onCloseModal('addClient')
			this.onCloseModal('searchClient')
			this.$store.commit('clients/SET_STATE', {
				clientsList: [],
			})
		},
		onAddClient() {
			this.onOpenModal('addClient')
		},
		onCreateClient() {
			document.getElementById('clientDetailGeneralComponent_submit').click()
		},
	},
	watch: {
		productsList: {
			deep: true,
			handler(newData) {
				if (newData.length == 1) {
					this.onSelectProduct(newData[0])
				} else {
					this.modal.products.visible = true
				}
			},
		},
		priceTabList: {
			deep: true,
			handler(newData) {
				if (newData.length > 0) {
					this.modal.services.visible = true
				}
			},
		},
	},
}
</script>
